import React, { useRef, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { BodyStyle, DesktopOnly, H4, IndicationTitle, MobileOnly, PreviewTitleStyle } from '../../util/typography';
import PageHeader from '../PageHeader';
import * as colors from '../../util/colors';
import {
    hasScrolledPassed,
    once,
    checkAndTriggerCancerTypeChange,
    createMarkup,
} from '../../util/functions';
import { createOverlay } from '../../components/Overlay';
import { createSelector } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux';
import useOnClickOutside from 'use-onclickoutside';
import {
    HAS_DISMISSED_INDICATION_PREVIEW_KEY,
    HAS_SCROLLED_STORAGE_KEY,
} from '../../store/storage-keys';
import { CSSTransition } from 'react-transition-group';
import { plusIconDarkGreen } from '../../util/icons';
import iconClose from '../../assets/content-icons/icon-close-x-circle.svg';
import iconCloseHover from '../../assets/content-icons/icon-close-x-circle-hover.svg';
import iconCloseMulHover from '../../assets/content-icons/icon-close-x-mulberry.svg';

import {
    allowScrolling,
    preventScrolling,
    openIndicationDrawerPreview,
    minimizeIndicationDrawerPreview,
    toggleIndicationDrawer,
    toggleSafetyDrawerDesktop,
    toggleSafetyKeytrudaDrawer,
    toggleSafetyLenvimaDrawer,
    indicateScrolledPastInPageSSI,
    indicateNotScrolledPastInPageSSI,
    openOverlay,
    closeOverlay,
    closeAllMenuItems,
    closeMobileMenu,
    closeFilter,
} from '../../store/actions';
import {
    INDICATIONS_AND_SAFETY_CONTAINER,
    INDICATIONS_DRAWER_LAUNCH_BUTTON,
    SAFETY_KEYTRUDA_DRAWER_LAUNCH_BUTTON,
    INDICATIONS_INFORMATION_DRAWER,
    SAFETY_KEYTRUDA_INFORMATION_DRAWER,
    SAFETY_LENVIMA_DRAWER_LAUNCH_BUTTON,
    SAFETY_LENVIMA_INFORMATION_DRAWER,
    SAFETY_DESKTOP_DRAWER_LAUNCH_BUTTON,
    SAFETY_DESKTOP_INFORMATION_DRAWER,
    INDICATIONS_AND_SAFETY_DRAWER_OVERLAY,
} from '../../constants/z-index-map';
import IndicationsList from './IndicationsList';
import SafetyList from './SafetyList';
import {default as globalSafetyData} from './data/globalSafety';
import { createIndicationPreviewText } from './data';
import { breakpoints, respondTo } from '../../util/breakpoints';

const LaunchButtonContainer = styled.div`
    display: flex;
    flex-flow: column nowrap;
    position: fixed;
    width: 100%;
    box-sizing: border-box;
    bottom: 0;
    cursor: pointer;
    max-width: 1032px;
    margin: 0 auto;
    left: 0;
    right: 0;
    padding: 0;
    pointer-events: none;
    transition: transform 0.3s ease-in-out;
    /* @todo Use real transition values for this, not just '300%' */
    transform: ${({ isCollapsed }) => (isCollapsed ? 'translateY(300%)' : 'none')};
    z-index: ${INDICATIONS_AND_SAFETY_CONTAINER};
    > * {
        pointer-events: auto;
    }
    keytitle {
        color: ${colors.brandGreen};
    }
    lentitle {
        color: ${colors.mulberry};
    }
`;

const LaunchButton = styled.div`
    overflow: hidden;
    border-radius: 6px 6px 0px 0px;
    box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.15);
    background-color: ${colors.white};
    box-sizing: border-box;
    position: relative;

    header {
        display: flex;
        justify-content: space-between;
        img {
            margin-left: 8px;
            width: 16px;
            height: 16px;
            ${respondTo.md`
                width: 15px;
                height: 15px;
            `}
        }
    }

    ${respondTo.md`
        transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
    `}
`;

const IndicationsLaunchButton = styled(LaunchButton)`
    height: ${({ isOpen }) => (isOpen ? '122px' : '46px')};
    z-index: ${INDICATIONS_DRAWER_LAUNCH_BUTTON};
    padding: 8px 10px;
    margin-bottom: -10px;
    transition: height 0.3s ease-in-out, padding 0.3s ease-in-out;
    color: ${colors.charcoal};

    > div {
        display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
    }
    ${respondTo.md`
        padding: ${({ isOpen }) => (isOpen ? '10px' : '7px')} 14px;
        height: ${({ isOpen }) => (isOpen ? '106px' : '46px')};
    `}
`;

const SafetyLaunchButton = styled(LaunchButton)`
    height: 96px;
    z-index: ${({zindex}) => zindex};
    margin-bottom: -5px;
    padding: 8px 10px;

    ${respondTo.md`
        padding: 0;
        height: 110px;
    `}
`;

const PreviewTitle = styled.div`
    ${PreviewTitleStyle};
    margin-bottom: 2px;
    ${respondTo.md`
        margin-bottom: 4px;
    `}
`;

const PreviewText = styled.div`
    ${BodyStyle};
    line-height: 16px;
    color: ${colors.charcoal};
    display: flex;
    flex-direction: column;
    gap: 8px;
    ${respondTo.md`
        line-height: 17px;
    `}
`;

const CloseButton = styled.button`
    position: absolute;
    top: 10px;
    right: 15px;
    background: none;
    padding: 0;
    border: 0;
    color: ${colors.white};
    line-height: 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    padding-right: 32px;
    &:after {
        background: url(${iconClose});
        position: absolute;
        right: 0;
        content: '';
        width: 24px;
        height: 24px;
    }
    &:hover {
        text-decoration: underline;
        &:after {
            background: url(${({type}) => type === 'lenvima' ? iconCloseMulHover : iconCloseHover});
        }
    }
    ${respondTo.md`
        right: 30px;
    `}
`;

const InformationDrawer = styled.div`
    position: fixed;
    height: 100%;
    // overflow: hidden;
    top: 100vh;
    left: 0;
    right: 0;
    z-index: ${({ zindex }) => zindex };
    transition: 0.4s transform ease-in-out;
    max-width: 1032px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    display: none;
    color: ${colors.charcoal};

    [class*='__PageHeaderContainer']{
        border-radius: 10px 10px 0px 0px;
        height: 44px;
        ${({drawerName}) => (drawerName === 'indications' || drawerName === 'safety-desktop') && `
            border-bottom: 3px solid ${colors.mulberry};
        `}
    }

    [class*='__PageHeaderContainer']{
        z-index: unset;
    }

    > .container {
        display: flex;
        flex-direction: column;
        background-color: ${colors.white};
        padding: 15px 25px 15px 15px;
        overflow: auto;
        padding-bottom: ${({ drawerName }) => (drawerName === 'indications' ? '30px' : '0px')};
        height: 100%;
        [class*='__IndicationTitle']{
            margin-bottom: 15px;
            ${respondTo.md`
                margin-bottom: 9px;
            `}
        }
        margin-bottom: ${({ drawerName }) => (drawerName === 'indications' ? '182px' : '0px')};
        ${respondTo.md`
            padding: 30px 50px 30px 30px;
            margin-bottom: ${({ drawerName }) => (drawerName === 'indications' ? '93px' : '0px')};
        `}
        &.ssi-mobile {
            [class*='__H4']{
                margin-bottom: 10px;
            }
            [class*='__SSIFooter']{
                margin-top: 10px;
            }
            a, a:visited {
                color: ${colors.brandGreen};
                &.lenvima, .lenvima:visited {
                    color: ${colors.mulberry};
                }
            }
            &.keytruda {
                margin-bottom: 91px;
            }
        }
    }

    &.information-drawer-enter {
        transform: translateY(0);
        opacity: 1;
        display: flex;
    }

    &.information-drawer-enter-active,
    &.information-drawer-enter-done {
        transform: translateY(-100vh);
        opacity: 1;
        display: flex;
    }

    &.information-drawer-exit {
        transform: translateY(-100vh);
        display: flex;
    }

    &.information-drawer-exit-active,
    &.information-drawer-exit-done {
        transform: translateY(0);
        display: flex;
    }

    &.information-drawer-exit-done {
        opacity: 0;
        display: none;
    }
`;

const Columns = styled.div`
    display: flex;
    gap: ${({isOpen}) => isOpen ? '40px' : '26px'};
    position: relative;
    [class*='__SSIFooter']{
        margin-top: 10px;
    }
`;

const Column1 = styled.div`
    width: calc(50% - 13px);
    padding: ${({isOpen}) => isOpen ? '0' : '6.8px 0px 0 13px'};
    position: relative;
    [class*='__PreviewTitle']{
        padding-right: 34px;
    }
    [class*='__H4']{
        margin-bottom: 10px;
    }
    &:after {
        content: '';
        position: absolute;
        width: 1px;
        height: calc(100% - 6.89px);
        top: 6.89px;
        right: ${({isOpen}) => isOpen ? '-20px' : '-13px'};
        background: ${colors.pebble};
    }
    a, a:visited {
        color: ${colors.brandGreen};
    }
`;

const Column2 = styled.div`
    width: calc(50% - 13px);
    padding: ${({isOpen}) => isOpen ? '0' : '6.8px 14px 0 0px'};
    [class*='__PreviewTitle']{
        padding-right: 34px;
    }
    [class*='__H4']{
        margin-bottom: 10px;
    }
    a, a:visited {
        color: ${colors.mulberry};
    }
`;

const SafetyDesktop = styled.div`
    display: none;
    ${respondTo.md`
        display: block;
        z-index: ${SAFETY_DESKTOP_DRAWER_LAUNCH_BUTTON};
    `}
`;

const SafetyMobile = styled.div`
    display: block;
    z-index: ${SAFETY_DESKTOP_DRAWER_LAUNCH_BUTTON};
    ${respondTo.md`
        display: none;
    `}
`;

export const STICKY_ISI_OVERLAY_KEY = 'STICKY_ISI';
export const StickyIsiOverlay = createOverlay(STICKY_ISI_OVERLAY_KEY, {
    extendsOverlay: StyledOverlay =>
        styled(StyledOverlay)`
            z-index: ${INDICATIONS_AND_SAFETY_DRAWER_OVERLAY};
        `,
});

const isiDrawerSelector = createSelector(
    state => state.indicationDrawerPreviewOpen,
    state => state.indicationDrawerOpen,
    state => state.safetyDrawerDesktopOpen,
    state => state.safetyKeytrudaDrawerOpen,
    state => state.safetyLenvimaDrawerOpen,
    state => state.overlayActive,
    state => state.scrollLock,
    (
        indicationDrawerPreviewOpen,
        indicationDrawerOpen,
        safetyDrawerDesktopOpen,
        safetyKeytrudaDrawerOpen,
        safetyLenvimaDrawerOpen,
        overlayActive,
        scrollLock
    ) => ({
        indicationDrawerPreviewOpen,
        indicationDrawerOpen,
        safetyDrawerDesktopOpen,
        safetyKeytrudaDrawerOpen,
        safetyLenvimaDrawerOpen,
        overlayActive,
        scrollLock,
    })
);

const IndicationAndSafetyDrawer = ({
    inline_indications_ref,
    launch_button_container_ref,
    hcp_acknowledgement_ref,
    safetyDataOverride,
    indicationId, //if there is an indicationId here, it means we are 'filtering the drawer' 
}) => {
    const { indicationDrawerPreviewOpen, indicationDrawerOpen, safetyDrawerDesktopOpen, safetyKeytrudaDrawerOpen, safetyLenvimaDrawerOpen } = useSelector(
        isiDrawerSelector
    );
    let scrolled_passed = useSelector(state => state.scrolledPastInPageSSI);
    const dispatch = useDispatch();

    useEffect(() => {
        let removeInteresctionObserver = hasScrolledPassed({
            element: inline_indications_ref && inline_indications_ref.current,
            scrolledPassedFn: () => {
                dispatch(indicateScrolledPastInPageSSI());
            },
            stillBeneathFn: () => {
                dispatch(indicateNotScrolledPastInPageSSI());
            },
        });

        // On unmount / re-render, remove our interesction observer
        return removeInteresctionObserver;
    }, []);

    useEffect(() => {
        window.addEventListener('resize', () => {
            closeDrawers();
        });
        return () => window.removeEventListener('resize', () => {
            closeDrawers();
        });
    }, []);

    /* this was in Matt's orignal branch */

    // We really only want to run this once, even if indicationDrawerPreviewOpen or dispatch change (I think!)
    // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (indicationDrawerPreviewOpen && typeof window !== 'undefined') {
            return once(window, 'scroll', function () {
                // Storage value is only used when redux state is initialized (aka on page refreshes)
                window.sessionStorage.setItem(HAS_SCROLLED_STORAGE_KEY, '1');
                dispatch(minimizeIndicationDrawerPreview());
            });
        }
    }, [dispatch, indicationDrawerPreviewOpen]);

    /*end this was in Matt's orignal branch */

    // useEffect(() => {
    //     /**
    //      * Scroll overflow'd indications content to top before it opens.
    //      * This is because if we scroll down, close, then reopen, the browser
    //      * will keep that scroll position.
    //      */
    //     indications_scrollable_ref.current.scrollTo(0, 0);
    // }, [indicationDrawerPreviewOpen]);

    const setIndicationPreviewSessionToken = useCallback(() => {
        if (typeof window !== 'undefined' && window.origin !== 'null') {
            window.sessionStorage.setItem(HAS_DISMISSED_INDICATION_PREVIEW_KEY, '1');
        }
    }, []);

    const closeIndicationsPreview = useCallback(() => {
        //close the preview state, if it isn't already
        setIndicationPreviewSessionToken();
        dispatch(minimizeIndicationDrawerPreview());
    }, [setIndicationPreviewSessionToken, dispatch]);

    useEffect(() => {
        if (typeof window !== 'undefined' && window.origin !== 'null') {
            let has_dismissed_preview = window.sessionStorage.getItem(
                HAS_DISMISSED_INDICATION_PREVIEW_KEY
            );

            if (!has_dismissed_preview) {
                dispatch(openIndicationDrawerPreview());
            }
        }
    }, [dispatch, closeIndicationsPreview, setIndicationPreviewSessionToken]);

    useEffect(() => {
        //since the indications preview state should only be 'on first page view', set the cookie now so it will not open again this browser session
        setIndicationPreviewSessionToken();
    }, [setIndicationPreviewSessionToken]);

    const indications_scrollable_ref = useRef(null);
    const indications_drawer_ref = useRef(null);
    const safety_drawer_desktop_ref = useRef(null);
    const indication_launch_button_ref = useRef(null);

    const safety_scrollable_ref = useRef(null);
    const safety_keytruda_drawer_ref = useRef(null);
    const safety_lenvima_drawer_ref = useRef(null);

    const openIndications = () => {
        dispatch(closeAllMenuItems());
        dispatch(closeMobileMenu());
        dispatch(openOverlay(STICKY_ISI_OVERLAY_KEY));
        dispatch(toggleIndicationDrawer());
        dispatch(closeFilter());
        dispatch(preventScrolling());
        closeIndicationsPreview();
        checkAndTriggerCancerTypeChange();
    };
    const openSafety = (ssiName) => {
        // Similar to scrollable indications, scroll safety content to top
        // safety_scrollable_ref.current.scrollTo(0, 0);
        dispatch(closeAllMenuItems());
        dispatch(closeMobileMenu());
        dispatch(openOverlay(STICKY_ISI_OVERLAY_KEY));
        if(ssiName === "keytruda"){
            dispatch(toggleSafetyKeytrudaDrawer());
        }else if(ssiName === "lenvima"){
            dispatch(toggleSafetyLenvimaDrawer());
        } else if(ssiName === "safety-desktop"){
            dispatch(toggleSafetyDrawerDesktop());
        }
        dispatch(closeFilter());
        dispatch(preventScrolling());
        checkAndTriggerCancerTypeChange();
    };

    const closeDrawers = () => {
        /**
         * @todo create `close` action creators for these
         * e.g. `dispatch(closeIndicationDrawer())` and `dispatch(closeSafetyDrawer())`
         */
        if (indicationDrawerOpen || safetyKeytrudaDrawerOpen || safetyLenvimaDrawerOpen || safetyDrawerDesktopOpen) {
            if(safetyDrawerDesktopOpen) {
                dispatch(toggleSafetyDrawerDesktop());
            }
            if (indicationDrawerOpen) {
                dispatch(toggleIndicationDrawer());
            }
            if (safetyKeytrudaDrawerOpen) {
                dispatch(toggleSafetyKeytrudaDrawer());
            }
            if(safetyLenvimaDrawerOpen) {
                dispatch(toggleSafetyLenvimaDrawer());
            }
            dispatch(closeOverlay());
            dispatch(allowScrolling());
        }
    };

    useOnClickOutside(indication_launch_button_ref, e => {
        /**
         * If we click on the HCP Acknowledgement bail early.
         */
        if (hcp_acknowledgement_ref?.current?.contains(e.target)) {
            return;
        }

        if (indicationDrawerPreviewOpen) {
            closeIndicationsPreview();
        }
    });

    /**
     * I want to do extra checks here because otherwise, clicking on the Indications
     * drawer while it is open triggers the clickOutside logic for the Safety Drawer,
     * thus closing everything.
     */
    useOnClickOutside(safety_drawer_desktop_ref, () => {
        if (safetyDrawerDesktopOpen) {
            closeDrawers();
        }
    });
    useOnClickOutside(indications_drawer_ref, () => {
        if (indicationDrawerOpen) {
            closeDrawers();
        }
    });
    useOnClickOutside(safety_keytruda_drawer_ref, () => {
        if (safetyKeytrudaDrawerOpen) {
            closeDrawers();
        }
    });
    useOnClickOutside(safety_lenvima_drawer_ref, () => {
        if (safetyLenvimaDrawerOpen) {
            closeDrawers();
        }
    });

    return (
        <div>
            <LaunchButtonContainer ref={launch_button_container_ref} isCollapsed={scrolled_passed}>

                <IndicationsLaunchButton
                        isOpen={indicationDrawerPreviewOpen}
                        onClick={openIndications}
                        ref={indication_launch_button_ref}
                        data-design-category="indication_vd"
                    >
                    <header>
                        {indicationDrawerPreviewOpen ?  <PreviewTitle>Indications for <keytitle>KEYTRUDA®&nbsp;(pembrolizumab)</keytitle> + <lentitle>LENVIMA®&nbsp;(lenvatinib)</lentitle></PreviewTitle> : <><DesktopOnly><PreviewTitle>Indications for <keytitle>KEYTRUDA®&nbsp;(pembrolizumab)</keytitle> + <lentitle>LENVIMA®&nbsp;(lenvatinib)</lentitle></PreviewTitle></DesktopOnly><MobileOnly><PreviewTitle>Expand for Indications</PreviewTitle></MobileOnly></>}
                        <img src={plusIconDarkGreen} alt='' />
                    </header>
                    <div>
                        <PreviewText dangerouslySetInnerHTML={createMarkup(createIndicationPreviewText(indicationId))} />
                    </div>
                </IndicationsLaunchButton>

                <CSSTransition
                    in={indicationDrawerOpen}
                    timeout={400}
                    classNames="information-drawer"
                >
                    <InformationDrawer
                        isOpen={indicationDrawerOpen}
                        ref={indications_drawer_ref}
                        zindex={INDICATIONS_INFORMATION_DRAWER}
                        drawerName="indications"
                    >
                        <PageHeader noBorder/>
                        <CloseButton onClick={closeDrawers}>Close </CloseButton> 
                        <div className="container">
                            <IndicationTitle>Indications for <keytitle>KEYTRUDA®&nbsp;(pembrolizumab)</keytitle> + <lentitle>LENVIMA®&nbsp;(lenvatinib)</lentitle></IndicationTitle>
                            <IndicationsList
                                scrollable_ref={indications_scrollable_ref}
                                indicationId={indicationId}
                            />
                        </div>
                    </InformationDrawer>
                </CSSTransition>
                <SafetyDesktop>
                    <SafetyLaunchButton
                        onClick={() => openSafety("safety-desktop")}
                        zindex = {SAFETY_DESKTOP_DRAWER_LAUNCH_BUTTON}
                        data-design-category="isi_ref_vd"
                    >
                        <Columns>
                            <Column1>
                                <header>
                                    <PreviewTitle><keytitle>Selected Safety Information for KEYTRUDA®&nbsp;(pembrolizumab)</keytitle></PreviewTitle>
                                    <img src={plusIconDarkGreen} alt='' />
                                </header>
                                <div>
                                    <PreviewText dangerouslySetInnerHTML={{ __html: (safetyDataOverride) ? safetyDataOverride.preview : globalSafetyData.preview.keytruda }} />
                                </div>
                            </Column1>
                            <Column2>
                                <header>
                                    <PreviewTitle><lentitle>Selected Safety Information for LENVIMA®&nbsp;(lenvatinib)</lentitle></PreviewTitle>
                                    <img src={plusIconDarkGreen} alt='' />
                                </header>
                                <div>
                                    <PreviewText dangerouslySetInnerHTML={{ __html: (safetyDataOverride) ? safetyDataOverride.preview : globalSafetyData.preview.lenvima }} />
                                </div>
                            </Column2>
                        </Columns>
                    </SafetyLaunchButton>
                    <CSSTransition in={safetyDrawerDesktopOpen} timeout={400} classNames="information-drawer">
                        <InformationDrawer
                            isOpen={safetyDrawerDesktopOpen}
                            ref={safety_drawer_desktop_ref}
                            zindex={SAFETY_DESKTOP_INFORMATION_DRAWER}
                            drawerName='safety-desktop'
                        >
                            <PageHeader noBorder/>
                            <CloseButton onClick={closeDrawers}>Close </CloseButton> 
                            <div className="container">
                                <Columns isOpen={safetyDrawerDesktopOpen}>
                                    <Column1 isOpen={safetyDrawerDesktopOpen}>
                                        <H4><b><keytitle>Selected Safety Information for KEYTRUDA®&nbsp;(pembrolizumab)</keytitle></b></H4>
                                        <SafetyList scrollable_ref={safety_scrollable_ref} safetyDataOverride={safetyDataOverride} type='keytruda' />
                                    </Column1>
                                    <Column2 isOpen={safetyDrawerDesktopOpen}>
                                        <H4><b><lentitle>Selected Safety Information for LENVIMA®&nbsp;(lenvatinib)</lentitle></b></H4>
                                        <SafetyList scrollable_ref={safety_scrollable_ref} safetyDataOverride={safetyDataOverride} type='lenvima' />
                                    </Column2>
                                </Columns>
                            </div>
                        </InformationDrawer>
                    </CSSTransition>
                </SafetyDesktop>
                       
                <SafetyMobile>

                    <SafetyLaunchButton
                        onClick={() => openSafety("keytruda")}
                        zindex = {SAFETY_KEYTRUDA_DRAWER_LAUNCH_BUTTON}
                        data-design-category="isi_ref_vd"
                    >
                        <header>
                            <PreviewTitle><keytitle>Selected Safety Information for KEYTRUDA®&nbsp;(pembrolizumab)</keytitle></PreviewTitle>
                            <img src={plusIconDarkGreen} alt='' />
                        </header>
                        <div>
                            <PreviewText dangerouslySetInnerHTML={{ __html: (safetyDataOverride) ? safetyDataOverride.preview : globalSafetyData.preview.keytruda }} />
                                </div>
                    </SafetyLaunchButton>

                    <CSSTransition in={safetyKeytrudaDrawerOpen} timeout={400} classNames="information-drawer">
                        <InformationDrawer
                            isOpen={safetyKeytrudaDrawerOpen}
                            ref={safety_keytruda_drawer_ref}
                            zindex={SAFETY_KEYTRUDA_INFORMATION_DRAWER}
                        >
                            <PageHeader noBorder/>
                            <CloseButton onClick={closeDrawers}>Close </CloseButton> 
                            <div className="container ssi-mobile keytruda">
                                <H4><b><keytitle>Selected Safety Information for KEYTRUDA®&nbsp;(pembrolizumab)</keytitle></b></H4>
                                <SafetyList scrollable_ref={safety_scrollable_ref} safetyDataOverride={safetyDataOverride} type='keytruda' />
                            </div>
                        </InformationDrawer>
                    </CSSTransition>

                    <SafetyLaunchButton
                        onClick={() => openSafety("lenvima")}
                        data-design-category="isi_ref_vd"
                        zindex={SAFETY_LENVIMA_DRAWER_LAUNCH_BUTTON}
                    >
                        <header>
                            <PreviewTitle><lentitle>Selected Safety Information for LENVIMA®&nbsp;(lenvatinib)</lentitle></PreviewTitle>
                            <img src={plusIconDarkGreen} alt='' />
                        </header>
                        <div>
                            <PreviewText dangerouslySetInnerHTML={{ __html: (safetyDataOverride) ? safetyDataOverride.preview : globalSafetyData.preview.lenvima }} />
                        </div>
                    </SafetyLaunchButton>

                    <CSSTransition in={safetyLenvimaDrawerOpen} timeout={400} classNames="information-drawer">
                        <InformationDrawer
                            isOpen={safetyLenvimaDrawerOpen}
                            ref={safety_lenvima_drawer_ref}
                            zindex={SAFETY_LENVIMA_INFORMATION_DRAWER}
                        >
                            <PageHeader type='lenvima' noBorder/>
                            <CloseButton onClick={closeDrawers} type='lenvima'>Close </CloseButton> 
                            <div className="container ssi-mobile lenvima">
                                <H4><b><lentitle>Selected Safety Information for LENVIMA®&nbsp;(lenvatinib)</lentitle></b></H4>
                                <SafetyList scrollable_ref={safety_scrollable_ref} safetyDataOverride={safetyDataOverride} type='lenvima' />
                            </div>
                        </InformationDrawer>
                    </CSSTransition>
                </SafetyMobile>
                       
            </LaunchButtonContainer>

            <StickyIsiOverlay />
        </div>
    );
};

export default IndicationAndSafetyDrawer;
