export const indications = {
    1: {
        id: 1,
        modifier: "Advanced",
        name: "Renal Cell Carcinoma",
        shortName: "Advanced RCC",
        statement: "<p>KEYTRUDA, in combination with LENVIMA, is indicated for the first-line treatment of adult patients with advanced renal cell carcinoma (RCC).</p>"
    },
    2: {
        id: 2,
        modifier: "Advanced",
        name: "MSI&#8288;-&#8288;H/dMMR Endometrial Carcinoma",
        shortName: "Advanced MSI&#8288;-&#8288;H/dMMR Endometrial Carcinoma",
        statement: "<p>KEYTRUDA, in combination with LENVIMA, is indicated for the treatment of patients with advanced endometrial carcinoma that is mismatch repair proficient (pMMR) as determined by an FDA-approved test or not microsatellite instability-high (MSI-H), who have disease progression following prior systemic therapy in any setting and are not candidates for curative surgery or radiation.</p>"
    },
};
